import React from 'react';

export default function UseCases() {
  return (
    <div id="usecases" className="container mx-auto px-6 mt-12 mb-12">
      <h3 className="text-4xl font-bold mb-6 leading-tight text-center">
        Use Cases
      </h3>
      <div className="sm:max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto sm:px-10">
        <ul>
          <li className="list-disc mb-6">
            Know the result of your CRON scripts.
          </li>
          <li className="list-disc mb-6">
            Real-time failure alert for web platforms.
          </li>
          <li className="list-disc mb-6">
            Get a message when a sale is made or if a customer did a specific
            action. Like a purchase on your e-commerce site.
          </li>
          <li className="list-disc mb-6">
            Action or event reminders. Never forget that you have to renew that
            domain or SSL certificate.
          </li>
          <li className="list-disc mb-6">
            Receive specific data, notifications, values, or statistics from any
            service or IoT device.
          </li>
          <li className="list-disc mb-6">
            Shift workers that need company notifications during a specific
            period.
          </li>
          <li className="list-disc mb-6">Stock price or market alerts.</li>
        </ul>
      </div>
    </div>
  );
}
