import React from 'react';
import FAQSingle from './FAQSingle';

const faqs = [
  {
    question: 'How many notifications can I receive when using NotiRobot',
    answer:
      'You may receive as many as you like with a practical limit of 1 every 1 minute to avoid clogging your devices, also restricted to the maximum frequency of notifications by platform',
  },
  {
    question: 'How do I register my phone to receive notifications?',
    answer:
      'Download NotiRobot for Android or iOS, enter or scan your account code and you are done! The app is lightweight and it only generates notifications',
  },
  {
    question: 'How do I register my laptop to receive notifications?',
    answer:
      'Enter NotiRobot website with your Firefox/Chrome browser, allow the site to send you notifications and you are done!',
  },
  {
    question: 'How can I push a new notification?',
    answer:
      'Just send a post request to the REST API and your notification will be created. You can also use GraphQL and MQTT if you want. Soon there will integrations to other services.',
  },
  {
    question: 'What do I need to push a new notification?',
    answer:
      'You just need your NotiRobot account created and your access token',
  },
  {
    question: 'Can my device receive push notifications from NotiRobot?',
    answer:
      'NotiRobot is compatible with the latest versions of Android, iOS, Firefox and Chrome. If your device is compatible with any of these platforms you should be able to receive notifications',
  },
  {
    question: 'Can I stop all notifications from NotiRobot?',
    answer:
      'Yes, you can configure when to receive notifications and also stop all of them, check our API documentation to generate the stop request, or do it using NotiRobot website',
  },
  {
    question: "How can I delete a notification that I don't want to receive?",
    answer:
      'You can restrict the sources of notification generation by identification or by text (regex)',
  },
  {
    question: 'Can I see the notifications history log?',
    answer:
      'Yes, you can obtain your history log through our API or the website',
  },
  {
    question: 'Can I filter when I receive the notifications?',
    answer:
      'Yes, you can set up when to receive notifications configuring which days and hours you would like to receive them',
  },
  {
    question: 'How much does it cost?',
    answer:
      'NotiRobot is free of charge until for solo developers up to 5 different services, for teams check our plans for you and your organization',
  },
  {
    question: 'How can I send notifications to my whole work team?',
    answer:
      'Register your team in a group in your NotiRobot account, and all notifications sent to this group will be received by the whole team',
  },
  {
    question: 'Can I choose which devices will receive a certain notification?',
    answer:
      'Coming soon, you will be able to create rules to control notifications',
  },
  {
    question: 'Can I integrate NotiRobot with X service?',
    answer: 'If you can integrate a request, you can integrate NotiRobot',
  },
  {
    question: 'Who is it for?',
    answer:
      'NotiRobot is for developers that want an easy and concentrated solution to manage and implement alerts, regardless of the platforms being used',
  },
  {
    question: 'Is there a SDK available?',
    answer:
      'Check our page to see examples for various integrations in several languages and frameworks',
  },
  {
    question: 'How long will the notifications last in my history log?',
    answer:
      'Notifications will be available for 7 days for the free plan and up to 30 days for pay plans, with a limit of 100000 notifications, if you reach the limit the oldest notification will be deleted when as the new one arrives',
  },
  {
    question: 'Is it safe?',
    answer:
      'NotiRobot uses SSL and secret tokens, as long as you keep this info private, NotiRobot should be safe',
  },
  {
    question: 'What about privacy?',
    answer:
      "Only you and your team have access to the notification messages. We don't read your messages.",
  },
];

export default function FAQ() {
  return (
    <div id="faq" className="container mx-auto px-6 mt-12 mb-12">
      <h3 className="text-4xl font-bold mb-6 leading-tight text-center">FAQ</h3>
      <div className="sm:max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto sm:px-10">
        {faqs.map(f => (
          <FAQSingle question={f.question} answer={f.answer} />
        ))}
      </div>
    </div>
  );
}
