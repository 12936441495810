import React from 'react';

export default function BoxServices({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) {
  return (
    <div className="max-w-full rounded overflow-hidden shadow-lg">
      <div className="px-6 py-4">
        <div className="font-bold text-l mb-2">Q: {question}</div>
        <p className="text-gray-600 text-base">A: {answer}</p>
      </div>
    </div>
  );
}
