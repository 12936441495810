import React from 'react';

import SoftwareIcon from '../assets/software.svg';
import UxIcon from '../assets/ux.svg';
import TecIcon from '../assets/tec.svg';

import BoxServices from './BoxServices';

export default function Services() {
  return (
    <div id="howwork" className="container mx-auto px-6 mt-12">
      <h3 className="text-4xl font-bold mb-6 leading-tight text-center">
        Just 3 steps
      </h3>
      <div className="flex flex-wrap mb-4">
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="1. Connect the Event"
            text="You only need a line of code to connect your software. You can also define another event source, such as a sale in an e-commerce site."
            icon={<TecIcon className="mx-auto" />}
          />
        </div>
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="2. NotiRobot does the magic"
            text="We receive the notification, classify it, and send it to the right device at the right time."
            icon={<SoftwareIcon className="mx-auto" />}
          />
        </div>
        <div className="md:w-1/3 text-center p-6">
          <BoxServices
            title="3. Receive the notification"
            text="It doesn't matter if you are on your phone, laptop, or browser. Your notification will arrive."
            icon={<UxIcon className="mx-auto" />}
          />
        </div>
      </div>
      <div className="text-center">
        <a
          type="button"
          className="bg-indigo-900 hover:bg-indigo-700 font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider text-white border-indigo-300"
          href="https://cdn.forms-content.sg-form.com/89715861-5a5d-11ea-ba37-926dcde01e69"
          target="_blank"
          rel="noopener noreferrer"
        >
          Be a beta tester
        </a>
      </div>
    </div>
  );
}
