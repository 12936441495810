import React, { useState, FormEvent } from 'react';

import Layout from '../components/Layout';

import Goals from '../components/Goals';
import HowWork from '../components/HowWork';
import UseCases from '../components/UseCases';
import FAQ from '../components/FAQ';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

export default function IndexPage() {
  const [nombre, setName] = useState('');
  const [correo, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [mensaje, setMessage] = useState('');
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactMessage, setContactMessage] = useState('');

  type Data = {
    name: string;
    email: string;
    message: string;
    telephone: string;
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setMessage('');
    setTelephone('');
    setErrors({
      name: false,
      email: false,
      message: false,
    });
    setContactError(false);
  };

  const validateForm = (data: Data) => {
    const { name, email, message } = data;
    let contactMes = '';
    let valid = true;
    const error = { name: false, email: false, message: false };
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!name) {
      error.name = true;
      contactMes = 'Name, email and message are required.';
      valid = false;
    }

    if (!email) {
      error.email = true;
      contactMes = 'Name, email and message are required.';
      valid = false;
    }

    if (!message) {
      error.message = true;
      contactMes = 'Name, email and message are required.';
      valid = false;
    }

    if (email && !re.test(email)) {
      error.email = true;
      contactMes = 'Please verify that your email address is correct.';
      valid = false;
    }

    setErrors(error);
    setContactMessage(contactMes);

    return valid;
  };

  const handleContact = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    const data = {
      name: nombre,
      email: correo,
      telephone,
      message: mensaje,
    };

    if (!validateForm(data)) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        'https://xo0avwgnya.execute-api.us-east-1.amazonaws.com/dev/mail',
        {
          method: 'post',
          body: JSON.stringify(data),
        },
      );
      if (!response.ok) {
        setLoading(false);
        setContactError(true);
        setContactMessage('There has been an error please verify your data');
      } else {
        setLoading(false);
        setContactError(false);
        setSuccess(true);
        setContactMessage(
          'Thank you for send us your information, we will put in contact shortly.',
        );
        handleReset();
      }
    } catch (error) {
      setLoading(false);
      setContactError(true);
      setContactMessage('There has been an error please verify your data');
    }
  };

  const handleChange = (type: string, value: string) => {
    if (type === 'name') {
      setName(value);
    }
    if (type === 'email') {
      setEmail(value);
    }
    if (type === 'telephone') {
      setTelephone(value);
    }
    if (type === 'message') {
      setMessage(value);
    }
  };

  return (
    <Layout>
      <Goals
        title="Notifications made for you by you"
        description="NotiRobot is a service that allows you to control all the event notifications you generate easily. If you are a hardware or software developer, an e-commerce manager, security, logistics, or operations supervisor, and want you and your team to receive alerts in real-time of the important events, this is the service for you."
      />
      <HowWork />
      <UseCases />
      <FAQ />
      {/* <Contact
        handleContact={handleContact}
        name={nombre}
        email={correo}
        telephone={telephone}
        message={mensaje}
        handleChange={handleChange}
        errors={errors}
        contactError={contactError}
        contactMessage={contactMessage}
        isLoading={isLoading}
        success={success}
        title="How can we help you?"
        nameLabel="Name"
        emailLabel="Email"
        messageLabel="Message"
        telephoneLabel="Phone (optional)"
        actionLabel="Send message"
        namePlaceholder="Your name"
        emailPlaceholder="Your email"
        telephonePlaceholder="+00 XXXXXXXXX"
      /> */}
      {/* <Footer /> */}
    </Layout>
  );
}
